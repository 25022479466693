.event-app-logo {
  width: 80px;
  height: 80px;
  align-items: center;
}

.form-heading {
  color: #4b0082;
  font-weight: 450;
  font-size: 22px;
  text-align: center;
}

.form-sub-heading {
  color: #4b0082;
  font-weight: 350;
  font-size: 14px;
  text-align: center;
}

.form-checkbox {
  margin-left: 10px;
  transform: scale(1.5); /* Change the scale factor as desired */
  accent-color: #4b0082;
  margin-bottom: 5px;
}

.form-checkbox-label {
  font-size: 14px;
  color: #4b0082;
  margin-bottom: 5px;
}

.form-checkbox-statement {
  color: #4b0082;
  text-align: center;
}

.pin-verification-message {
  font-size: 10px;
  color: #777;
  text-align: center;
}

.step-progress {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.step-dot {
  background-color: #ddd;
  border-radius: 50%;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  height: 1.2rem;
  width: 1.2rem;
  margin-right: 0.5rem;
}

.step-dot.completed {
  background-color: #444;
}

.step-dot.active {
  background-color: #4b0082;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .profile-label {
    color: #4b0082;
    position: a;
    left: 50;
  }

  .profile-image-container {
    position: relative;
    width: 130px;
    height: 130px;
    margin-bottom: 2.5rem;
    // overflow: hidden;
    border-radius: 100%;
  }

  .profile-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }

  .change-image-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #fff;
    background-color: #4b0082;
    border: 1px solid #4b0082;
    padding: 8px 10px;
    border-radius: 50%;
    transition: background-color 0.3s, border-color 0.3s;
    cursor: pointer;
  }

  .change-image-btn:hover {
    background-color: #6d7fa3;
    border-color: #6d7fa3;
  }

  .error-message-container {
    display: inline;
    font-size: 11px;
    color: #ff0000;
    align-self: flex-end;
  }

  .sign-in-button {
    background-color: #4b0082;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}

.custom-popup-heading {
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 18px;
  color: #4b0082;
}
