.otp-screen {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.email-verification-event-name {
  text-align: center;
  font-size: 22px;
  color: #4b0082;
  font-weight: 450;
}

.email-verification-message {
  text-align: center;
  font-size: 16px;
  color: #4b0082;
}

.email-verification-message-two {
  text-align: center;
  font-size: 11px;
  color: #555;
  padding-bottom: 15px;
}

.back-arrow-container {
  position: absolute;
  top: 0;
  left: 0;

  :hover {
    cursor: pointer;
  }
}
