.event-information {
  font-weight: Bold;
  text-align: center;

  .event-name {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
  }
  .user-name {
    margin: 0;
    font-weight: 500;
    font-size: 20px;
  }

  // .user-email {
  //   margin: 0;
  //   text-align: center;
  //   font-size: 13px;
  //   font-weight: lighter;
  // }
}

.profile-image img {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 130px;
  height: 130px;
  object-fit: cover;
}

.profile-info {
  text-align: center;

  .invited-by {
    color: #555;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .invited-by-name {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 15px;
  }
}

// .profile-info h2 {
// margin-top: 16px;
// margin-bottom: 5px;
// font-size: 25px;
// }

.QR-optional-text {
  font-size: 12px;
  text-align: center;
}

// .QR-code-container {
//   display: inline-block;
//   width: fit-content;
//   padding: 10px 20px;
//   border: 2px solid #ccc;
//   border-radius: 5px;
//   background-color: #fff;
//   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
//   font-size: 16px;
//   font-weight: bold;

//   .reduce-type-text {
//     font-weight: Bold;
//     color: #dc3535;
//   }
//   .code {
//     text-align: center;
//     font-size: 12px;
//   }
// }

.QR-code-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  font-weight: bold;
}

.QR-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.QR-reduce-type-text-container {
}

.QR-reduce-type-text {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 22px;
  color: #dc3535;
}

.QR-comps-type-text {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 22px;
  color: #006400;
}

.message-text {
  font-size: 12px;
  color: #777;
}

.code {
  text-align: center;
  font-size: 12px;
  margin-bottom: 0;
}

.text-container {
  margin-top: 10px;
  text-align: center;

  .venue-text {
    font-size: 12px;
    margin-bottom: 0;
  }
}
