.progress-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.dot {
  background-color: darkgrey;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  transition: all 0.5s;
}

.dot.active {
  background-color: #4b0082;
  width: 15px;
  height: 15px;
}
